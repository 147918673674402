<div class="container container-custom-changes" (click)="$event.stopPropagation()">
  <p *ngIf="title" class="color-text-primary text-reduced-bold mb-2 mt-0">{{ title | translate }}</p>
  <div class="d-flex justify-content-between align-items-center">
    <app-search-field
      [formControl]="searchFieldControl"
      placeholder="{{ 'MESSAGES.SEARCH_PARTICIPANTS' | translate }}"
      class="text-small-regular search-field"
    ></app-search-field>
    <div
      *ngIf="keyword$ | async"
      matRipple
      class="color-secondary text-reduced-bold ml-3 close-search-button"
      (click)="onCloseSearchClick()"
    >
      {{ 'MESSAGES.CLOSE_SEARCH' | translate }}
    </div>
  </div>
  <ng-container *ngIf="(keyword$ | async) === ''">
    <p *ngIf="defaultTeamMembersToDisplay.length" class="color-text-main-title text-tiny-regular opacity-40 mb-1 mt-3">
      {{ 'MESSAGES.PARTICIPANTS_LABELS.DEFAULT' | translate }}
    </p>
    <app-sendbird-message-participant-item
      *ngFor="let teamMember of defaultTeamMembersToDisplay"
      [teamMember]="teamMember"
      [added]="isAdded(teamMember)"
      [removable]="isRemovable(teamMember)"
      [participantSection]="participantSectionName"
      (add)="onAdd(teamMember)"
      (remove)="onRemove(teamMember)"
    ></app-sendbird-message-participant-item>

    <p *ngIf="taggedTeamMembersToDisplay.length" class="color-text-main-title text-tiny-regular opacity-40 mb-1 mt-3">
      {{ 'MESSAGES.PARTICIPANTS_LABELS.TAGGED' | translate }}
    </p>
    <app-sendbird-message-participant-item
      *ngFor="let teamMember of taggedTeamMembersToDisplay"
      [teamMember]="teamMember"
      [added]="isAdded(teamMember)"
      [removable]="isRemovable(teamMember)"
      [participantSection]="participantSectionName"
      [existingSendBirdChannelFlag]="existingChannelParticipantsFlag"
      (add)="onAdd(teamMember)"
      (remove)="onRemove(teamMember)"
    ></app-sendbird-message-participant-item>

    <div class="accordion" [@toggleContent]="isOpenForInternalUser ? 'open' : 'closed'" *ngIf="Internal_Users__c?.length">
      <div class="header d-flex align-items-center" (click)="onToggleClickForInternalUser()">
        <button type="button" mat-icon-button class="color-text-main-title">
          <svg-icon key="arrow-down" [class.up]="isOpenForInternalUser"></svg-icon>
        </button>
        <span class="third-party-client-account-title"> {{ sourceApp }} </span>
      </div>
      <div [@toggleContentOpacity]="isOpenForInternalUser ? 'open' : 'closed'" class="message-list">
        <div class="message mb-3">
          <app-sendbird-message-participant-item
            *ngFor="let teamMember of Internal_Users__c"
            [teamMember]="teamMember"
            [added]="isAdded(teamMember)"
            [removable]="isRemovable(teamMember)"
            [participantSection]="participantSectionName"
            (add)="onAdd(teamMember)"
            (remove)="onRemove(teamMember)"
          ></app-sendbird-message-participant-item>
        </div>
      </div>
    </div>

    <div class="accordion" [@toggleContent]="isOpenForClientUser ? 'open' : 'closed'" *ngIf="Client_Users__c?.length">
      <div class="header d-flex align-items-center" (click)="onToggleClickForClientUser()">
        <button type="button" mat-icon-button class="color-text-main-title">
          <svg-icon key="arrow-down" [class.up]="isOpenForClientUser"></svg-icon>
        </button>
        <span class="third-party-client-account-title"> {{ companyNameForClient }} </span>
      </div>
      <div [@toggleContentOpacity]="isOpenForClientUser ? 'open' : 'closed'" class="message-list">
        <div class="message mb-3">
          <app-sendbird-message-participant-item
            *ngFor="let teamMember of Client_Users__c"
            [teamMember]="teamMember"
            [added]="isAdded(teamMember)"
            [removable]="isRemovable(teamMember)"
            (add)="onAdd(teamMember)"
            (remove)="onRemove(teamMember)"
          ></app-sendbird-message-participant-item>
        </div>
      </div>
    </div>

    <div class="accordion" [@toggleContent]="isOpenForThirdPartyUsers ? 'open' : 'closed'" *ngIf="Affiliate_Users__c?.length">
      <div class="header d-flex align-items-center" (click)="onToggleClickForAffiliateUsers()">
        <button type="button" mat-icon-button class="color-text-main-title">
          <svg-icon key="arrow-down" [class.up]="isOpenForThirdPartyUsers"></svg-icon>
        </button>
        <span class="third-party-client-account-title"> Third Party </span>
      </div>
      <div [@toggleContentOpacity]="isOpenForThirdPartyUsers ? 'open' : 'closed'" class="message-list">
        <div class="message mb-3">
          <app-sendbird-message-participant-item
            *ngFor="let teamMember of Affiliate_Users__c"
            [teamMember]="teamMember"
            [added]="isAdded(teamMember)"
            [removable]="isRemovable(teamMember)"
            (add)="onAdd(teamMember)"
            (remove)="onRemove(teamMember)"
          ></app-sendbird-message-participant-item>
        </div>
      </div>
    </div>

    <div class="guest-title">
      <p class="color-text-main-title text-tiny-regular opacity-40 mb-1 mt-3">
        {{ 'MESSAGES.PARTICIPANTS_LABELS.GUEST_USER' | translate }}
      </p>
      <ng-container
        *ngIf="(sendbirdParticipants === null || sendbirdParticipants?.length === 0) && !guestUserDisableFlag; else guestUserTooltip"
      >
        <label
          class="custom-checkbox"
          [ngClass]="sendbirdParticipants?.length === 0 && !guestUserDisableFlag ? null : 'custom-checkbox-disable-toggle'"
          [appTooltip]="'MESSAGES.PARTICIPANTS_LABELS.GUEST_USER_DISABLE_MESSAGE' | translate"
          appTooltipStrategy="hover"
        >
          <input
            type="checkbox"
            [value]="guestUserToggle"
            [checked]="guestUserToggle"
            (change)="guestUserToggleChange(guestUserToggle)"
            [disabled]="sendbirdParticipants !== null || sendbirdParticipants?.length > 0 ? true : guestUserDisableFlag"
          />
          <span [ngClass]="{ checkmark: !guestUserDisableFlag }"></span>
        </label>
      </ng-container>

      <ng-template #guestUserTooltip>
        <div
          class="custom-checkbox custom-checkbox-disable-toggle"
          [ngClass]="guestUserToggle ? 'guest-user-toggle-enabled' : 'guest-user-toggle-disabled'"
          [appTooltip]="'MESSAGES.PARTICIPANTS_LABELS.GUEST_USER_DISABLE_MESSAGE' | translate"
          appTooltipStrategy="hover"
        ></div>
      </ng-template>
    </div>
    <div class="search-btn-box">
      <input
        type="text"
        class="guest-user-input"
        placeholder="Guest Email Address..."
        [ngClass]="guestUserToggle && !guestUserDisableFlag ? '' : 'guest-input-disabled'"
        [disabled]="!guestUserToggle"
        [(ngModel)]="guestUserEmail"
      />
      <div
        matRipple
        class="d-flex align-items-center button"
        [ngClass]="guestUserToggle ? 'color-secondary' : 'guest-user-disabled'"
        (click)="onAddClickForGuestUser()"
      >
        <svg-icon key="add"></svg-icon>
        <span class="text-normal-bold-message-participant pl-1">{{ 'COMMON.ADD' | translate }}</span>
      </div>
    </div>
    <div class="guest-user-list" *ngIf="listOfGuestUsers.length">
      <div *ngFor="let item of listOfGuestUsers; let i = index">
        {{ item.email }}
        <div matRipple class="d-flex align-items-center color-error button" (click)="removeForGuestUserList(i)">
          <svg-icon key="close"></svg-icon>
          <span class="text-normal-bold-message-participant pl-1">{{ 'COMMON.REMOVE' | translate }}</span>
        </div>
      </div>
    </div>
    <!-- add the button for + add -->
  </ng-container>
  <ng-container *ngIf="(keyword$ | async) !== ''">
    <app-sendbird-message-participant-item
      *ngFor="let teamMember of searchedTeamMembersToDisplay"
      [teamMember]="teamMember"
      [added]="isAdded(teamMember)"
      [removable]="isRemovable(teamMember)"
      [keyword]="keyword$ | async"
      [participantSection]="participantSectionName"
      (add)="onAdd(teamMember)"
      (remove)="onRemove(teamMember)"
    ></app-sendbird-message-participant-item>
  </ng-container>
</div>
